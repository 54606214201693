@font-face {
    font-family: Rubik;
}

;

.auth-header {
    position: relative;
    z-index: 1000;
    height: 80px !important; //9.7vh 80px
    width: 100%;
    background: #FFFFFF !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .logo-menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .menu-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border: none;
        text-align: right;

    }

    .row-head {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
}
