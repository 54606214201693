@import '~antd/dist/antd.css';

@font-face {
    font-family: Rubik;
    src: url("../../fonts/Rubik-VariableFont_wght.ttf");
}

body {
    font-family: Rubik !important;
}

.search-container {
    position: relative;
    width: 100%;
    left: 0;
    text-align: center;
    padding: 2em 0 2em 0;
}

.filter-hidden {
    margin-top: 10px;
    text-align: left !important;
    opacity: 0;
    transition: opacity 300ms ease-in;
}

.filter {
    margin-top: 10px;
    text-align: left !important;
    opacity: 1;
    transition: opacity 300ms ease-in;

    .filters {
        background: transparent !important;

        .ant-select-selector {
            border: 1px solid #0066CC !important;
            border-radius: 25px !important;
            color: #0066CC !important;

            .ant-select-selection-overflow{
                .ant-select-selection-overflow-item{
                    .ant-select-selection-item{
                        background: transparent !important;
                        border-radius: 16px;

                        border: 1px solid #0066CC;
                        .ant-select-selection-item-remove{
                            color: #0066CC;
                        }
                    }
                }
            }
        }

        .ant-select-selection-placeholder {
            color: #0066CC !important;
        }

        .ant-select-arrow {
            color: #0066CC !important;
        }

        .tags {
            border: none !important;
            margin-right: 3px;
        }

    }
}

.global-search {

    .ant-input-affix-wrapper {
        border-bottom-left-radius: 25px !important;
        border-top-left-radius: 25px !important;
    }

    .ant-input-search-button {
        border-bottom-right-radius: 25px !important;
        border-top-right-radius: 25px !important;
    }

    .ant-input-group-addon {
        background: transparent;
    }
}

.header-search {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    color: #FFFFFF;
    font-size: 3em;
}

.container body {
    width: 100vw;
    min-height: 120vh; //100vh 2232px
}

.auth-layout {
    min-height: 100vh !important;
    overflow: hidden;
}

.header {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 5%;

    .ant-layout-content {
        height: 100%;
        min-height: 100vh !important;
    }
}

.background-banner {
    background-image: url("../images/fondo_buscador.png");
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-size: 100% 42%; //100% 367px;
}

/* SMALL1 */
.small_1 {
    font-style: normal;
    font-size: 1.4em; // 0.9vw
    color: #A0A4A8;
}

/* SMALL2 */
.small_2 {
    font-style: normal;
    font-weight: normal;
    font-size: 1.1em; //0.8vw
    line-height: 1.5em;
    letter-spacing: 0.2px;
    color: #52575C;
}

/* SMALL 3 */
.small_3 {
    font-style: normal;
    font-weight: normal;
    font-size: 1em; //2.2vh 0.7vw
    line-height: 1em;
    /* or 127% */
    letter-spacing: 0.2px;
    /* Inactive state */
    color: #A0A4A8;
}

.home-content {
    position: relative;
    height: 100%;

    /**BANNER USER **/

    .banner-user {
        height: 30%; // 208px
        margin-top: 0; //40px 2.5%
        background: #FFFFFF;
        box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
        border-radius: 0.6em; //8px
    }

    .banner-usr-data {
        margin: 1% 1% 1% 2%;
    }

    .banner-tittle {
        margin-bottom: -1%;
        font-style: normal;
        font-size: 3em; //32px
        /* identical to box height, or 131% */
        letter-spacing: 0.1px;
        /* Black */
        color: #1D1D1F;
    }

    .banner-school {
        /* H4 */
        margin-bottom: 0.7%;
        font-style: normal;
        font-size: 2.4em; //26px
        /* identical to box height, or 123% */
        letter-spacing: 0.2px;
        /* ESE dark blue */
        color: #173C7B;
    }

    .banner-tag {
        margin-bottom: 2.5%; //26px
    }

    .banner-subject {
        font-style: normal;
        font-size: 1.7em; //16px
        line-height: 24px;
        /* identical to box height, or 150% */
        letter-spacing: 0.1px;
        /* Primary actions, buttons and links */
        color: #0066CC;
    }

    .banner-session {
        padding-top: 3%;
    }

    .session-close {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5em; //14px
        line-height: 18px;
        /* Red alert */
        color: #FF5C5C;
    }

    /** SEARCH INPUT **/

    .search {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-suffix {
        font-size: 1em;
        color: #1890FF;
    }

    .title-search-box {
        position: relative;
        text-align: center;
        font-size: 5.5em; //52px
        color: #FFFFFF;
        margin-top: 10%;
    }

    .search-input {
        width: 75%;
        position: center;
    }

    /** BTN CREATE RESOURCE AND FAVORITE **/

    .create-resource {
        position: relative;
        height: 75%; //64px
        margin-top: 4%; //116PX
        text-align: left;
        font-size: 3vh; //1.8em
        color: #0066CC;
        border-radius: 0.6em; //8px
        box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
    }

    .btn-favorite-text {
        color: #0066CC;
    }

    .btn-create-text {
        color: #FFFFFF !important;
    }

    .btn-option-home {
        text-align: left !important;
        border-radius: 0.6em !important; //8px
    }

    .background-btn-resource {
        background: #0066CC !important;
    }

    .container-buttons {
        margin-top: 2.5%;
    }

    /** RESOURCES **/

    .resources {
        position: relative;
        margin-top: 2%;
        border-radius: 90px !important;

        .resources-box-title {
            color: #173C7B;
            font-size: 1.7em;
        }
    }
}

.resource-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0 !important;
    margin: 0 !important;
}

.resource-child {
    background: #FFFFFF;
    width: 50%;
    height: 33.1vh;
}

.type-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

}

.black-target {
    box-shadow: inset 2px 0 3px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 0;
}

/********* CARD ********/

.card {
    width: 100%;
    min-height: 100%;
}

.card-box {
    margin-top: 1%;
    margin-bottom: 1%;
}

.ant-card {
    border-radius: 16px !important;
    box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
}

.ant-card-body {
    padding: 0 0 0 0 !important;
}

.title-card {
    padding-top: 3%;
    line-height: 16px;
    margin-bottom: 0;
}

.sub-title-card {
    font-size: 0.75em !important;
}

.ant-card-extra {
    color: #FFFFFF !important;
}

.subject-card {
    text-align: center !important;
}

.ant-card-head-title {
    color: #FFFFFF !important;
    padding: 16px 0 0 0 !important;
}

.sub_1 {
    font-family: Rubik;
    font-style: normal;
    font-size: 1.5em; //1.5em 1.2vw
    line-height: 1em;
    letter-spacing: 0.1px;
    color: #1D1D1F;
}

.card-content-label {
    margin-top: 1.7%;
    margin-bottom: 8%;
    width: auto;
}

.card-description {
    width: auto; //21.5vw
    height: 60%; // 14vh
    min-width: 100%;
    min-height: 5%;
}

//IMG CARD
.img-card {
    width: 100%;
    height: 90%;
    margin-left: auto;
    margin-right: auto;

    //position: absolute;
    //width: 90%; //48.6%
    //min-width: 48.6%;
    //height: 100%; //65.9%
}

.text-card {
    position: absolute;
    width: 21.2vw; //48.6%
    min-width: 48.6%;
    height: 28.5vh; //65.9%
    padding-left: 2%;
    padding-right: 2%;
    overflow-y: auto;
}

.resource-container-description {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
}

/** TABS FOR PROFILE **/
.tabs-profile {
    .ant-tabs-tab-btn {
        color: #A0A4A8;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #173C7B !important;
    }

    .ant-tabs-ink-bar {
        height: 5px;
        background: transparent !important;
    }

    .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom: none !important;
    }
}

//TAG
.ant-tag {
    border-radius: 100px !important;
    border: 1px solid #0066CC !important;
    color: #0066CC !important;
}

// PAGINATION
.pagination_home {
    display: flex;
    justify-content: center;
    padding: 1.5% 0 2% 0;
}

.ant-pagination-item-active {
    background: #0066CC !important;
}

.ant-pagination-item-link {
    border-radius: 8px !important;
    box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
    //width: 100% !important; //44px   Expected size according to design, it is disabled to be responsive
    //height: 5vh !important; //44px   Expected size according to design, it is disabled to be responsive
    color: #0066CC !important;
}

.ant-pagination-item {
    border-radius: 8px !important;
    box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
    //width: 2.5vw !important;   Expected size according to design, it is disabled to be responsive
    //height: 5vh !important;    Expected size according to design, it is disabled to be responsive
}

.ant-pagination-item a {
    color: #0066CC !important;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
}

.IconExport {
    height: 2.7vh;
    width: auto;
}
