.row-btn-plannings{
    padding-top: 2%;
}

.title-plannings{
    font-style: normal;
    font-weight: 300;
    font-size: 52px;
    line-height: 64px;
    /* identical to box height, or 123% */

    text-align: center;
    letter-spacing: 0.2px;

    /* ESE dark blue */

    color: #173C7B;
}
.btn-plannings {
    background: #0066CC !important;
    border-radius: 0.5em !important;
}

.select-plannings {
    .ant-select-selector {
        border: 1px solid #A0A4A8 !important;
        border-radius: 1em !important; //40px
        // FONT BODY - SELECT
        font-size: 1.1em; //16px
    }
    .ant-select-arrow {
        color: #0066CC !important;
    }
}