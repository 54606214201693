.resource-container {
    margin: 2em 0 2em 0;
    min-height: 20vh !important;

    .paginator {
        display: inline-flex;

        .ant-pagination-item {
            height: 100% !important;
        }

        .ant-pagination-item-link {
            height: 100% !important;
        }
    }

    .resources-wrap {
        border-radius: 14px !important;
        border: 0 !important;

        .card-body-list {
            height: 40% !important;
        }

        .ant-card-head {
            min-height: auto !important;
            padding: 0;
        }

        .ant-card-head-title {
            padding: 0 !important;
            border-top-right-radius: 14px !important;
            border-top-left-radius: 14px !important;
        }

        .resources {
            border: 0 !important;
            border-radius: 0 !important;
            width: 100%;

            .resource-main-content {
                padding-left: 1em;
            }
        }

        .resource-card-body-tex {
            padding: 10px 0 0 10px;
        }

        .resource-title {
            font-family: Rubik, serif !important;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            letter-spacing: 0.1px;

            /* Black */

            color: #1D1D1F;

            word-break: break-all;

            .title {
                margin-bottom: 0;
                white-space: normal !important;
            }
        }

        .resource-subtitle {
            font-size: .9em;
            margin: auto;
        }
    }

    .spin-home {
        display: flex;
        align-items: center;
    }
}

.rhap_container {
    border-radius: 14px;
    box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25) !important;
}

.rhap_additional-controls {
    display: none !important;
}

.rhap_volume-controls {
    //display: none !important;
}

.rhap_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
}

.rhap_main {
    width: 100%
}

.rhap_controls-section {
    display: flex;
    justify-content: center !important;
}

.icon-play-player {
    padding-bottom: 70%;
    height: 100%;
}
