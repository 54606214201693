#new-planning-component {
    table {
        tr td:first-child {
            text-align: left;
        }

        th, td {
            text-align: center;
        }
    }
}