.video-home {
    position: absolute;
    height: 100%;
    width: 100%;
}

.video-resource-action {
    video > {
        border-bottom-right-radius: 16px;
    }
}
