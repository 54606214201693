@import './default';

@font-face {
  font-family: Rubik;
}

// main background
footer.esefoot {
  background: #173C7B;
}

footer {
  clear: both;
  background: #FFF;
  position: relative;
  z-index: 100;
  color: #F9F9FA;

  .ant-row {
    text-align: center;

    .footer-center {
      display: inline-block;
      text-align: left;

      > div {
        margin: 2% 0;
      }
    }

    .title-foot {
      color: #F9F9FA;
      font-size: 1.3em;
    }
  }

  .item-foot {
    font-size: 1em;
  }

  .footer-wrap {
    position: relative;
    padding: 2% 10%;
  }

  .footer-text {
    color: white;
  }

  .foot-logo {
    //width: 15em;
    //height: 90%; //12vh
    margin-bottom: 20px;
  }

  .sub-text {
    text-align: left;
    font-size: 1em;
  }

}
