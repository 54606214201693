@import '../../../../node_modules/antd/dist/antd.less';

#update-planning-component {
    .inner-spinner-container {
        height: 80vh;
    }

    .ant-table-title {
        border-radius: 8px 8px 0 0;
        color: #FFFFFF;
        background: @primary-color;
    }
}