@import '~antd/dist/antd.less';

#approve-users-component {
    h1 {
        font-size: 43px;
        color: @primary-color;
        font-weight: bolder;
    }

    h3 {
        font-size: 26px;
        color: @primary-color;
        margin-bottom: 0 !important;
    }

    > .ant-space {
        margin-bottom: 1em;
    }

    > .school-selector-container {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin-bottom: 3.8em;

        > span {
            color: @primary-color;
            font-size: 1em;
            font-weight: 500;
            margin-bottom: .5em;
        }
    }

    .entity-table-action-button-group, .ant-btn-group {
        .ant-btn {
            background: #FFFFFF !important;
            border: none;
            color: @success-color;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
        }
    }

    .ant-space-item:last-child .ant-btn, .ant-btn-dangerous {
        color: @error-color !important;
    }

    .ant-table {
        border-radius: 10px;
    }
}