@font-face {
    font-family: Rubik;
}

.content-resource {
    display: flex;
    margin: -2% 2% 2% 2%;
    background: #FFFFFF;
    border-radius: 1em;
    box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
    padding: 2%;

    min-height: 30vh;
}

.title-resource {
    font-style: normal;
    font-weight: bold;
    font-size: 3.2em; //43px
    line-height: 0.9em; //44px
    color: #0066CC;
}

.card-content-resource {
    margin-bottom: 2% !important;
}

.title-sub-1 {
    /* SUB1 */
    font-style: normal;
    font-weight: bold;
    font-size: 1.4em;
    line-height: 1em; // 44px
    /* identical to box height, or 150% */
    letter-spacing: 0.02em; //0.1px
    /* Primary actions, buttons and links */
    color: #0066CC;
}

.item-resource {
    width: 100%; //68vw
    padding-bottom: 1%;
}

//SELECT RESOURCE
.select-resource {
    width: 100%;

    .ant-select-selector {
        border: 1px solid #A0A4A8 !important;
        border-radius: 1em !important; //40px
        // FONT BODY - SELECT
        font-size: 1.1em; //16px
    }

    .ant-select-arrow {
        color: #0066CC !important;
    }
}

//INPUT RESOURCE
.input-resource {
    border: 1px solid #A0A4A8 !important;
    border-radius: 1em !important; //40px
    // FONT BODY - SELECT
    font-size: 1.3em; //16px
}

// BUTTONS
.btn-primary-resources {
    text-align: center;
    color: #FFFFFF !important;
    background: #0066CC !important;
}

.btn-eraser-text {
    text-align: center;
    color: #0066CC !important;
}

//.ant-picker-focused
.ant-picker {
    border: 1px solid #A0A4A8 !important;
    border-radius: 1em !important;
    font-size: 1.3em;
}

//extra content
.title-sub-1-pr {
    color: #FFFFFF !important;
    font-size: 100%;
    padding: 0;
}

//text area extra content
.input-extra {
    width: 90% !important;
    margin: 2.5% 5% 2.5% 5% !important;
    border: 0.1em solid #A0A4A8 !important;
    box-sizing: border-box !important;
    border-radius: 1em !important;
    padding: 10px;
    display: compact;
}

.ant-card-bordered {
    border-radius: 0 !important;
}

//methodology
.input-methodology {
    margin-top: 1% !important;
    margin-left: 1.5% !important;
    margin-right: 1.5%;
    width: 97% !important;
}

// Suggested resource
.suggested-resource {
    margin: 0 1.5% 2.5% 1.5% !important;
    width: 97%;
}

.weekly-content {
    margin-bottom: 1%;
    margin-left: 0 !important;
    margin-right: 0.1% !important;
}

.weekly-resource {
    margin-left: 1.5% !important;
    margin-right: 1.5%;
    width: 97% !important;
}

.content-bnt-weekly {
    padding-top: 2.6%;
}

.btn-weekly {
    background: #0066CC !important;
    border-radius: 8px !important;
    color: #FFFFFF !important;
}

// footer
.footer-container {
    padding-top: 3%;
}

.footer-resource {
    color: #52575C;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.5em;
    /* or 133% */

    text-align: center;
    letter-spacing: 0.02em;
}

// view resource
.content-resource-view {
    width: 100%;

    .content-view-info {
        height: 100%;
        padding: 2% 1% 3% 5%;
    }
}

.content-view-resource {
    background: #FFFFFF;
    padding-left: 5%;
}

.test_table_row {
    width: 100%;
}

.content-recommendation-resource {
    padding-top: 1.5% !important;
    background: #F9F9FA;

    .title-recommendation {
        color: #173C7B;
        font-style: normal;
        font-weight: bold;
        font-size: 1.5em;
        line-height: 2em;
        letter-spacing: 0.03em;
    }

    .recommendation-card {
        margin-bottom: 5%;
        margin-right: 12%;
    }

    .body-card-recommendation {
        padding: 3% 10% 4% 5%;
    }

    .recommendation-title-card {
        font-style: normal;
        font-weight: bold;
        font-size: 1.4em;
        line-height: 1.2em;
        /* or 150% */
        letter-spacing: 0.01em;
        /* Black */
        color: #1D1D1F;
    }
}

.custom-component {
    padding-top: 5%;

    .title-recommendation {
        color: #173C7B;
        font-style: normal;
        font-weight: bold;
        font-size: 1.5em;
        line-height: 2em;
        letter-spacing: 0.03em;
    }

    .recommendation-card {
        margin-bottom: 5%;

        .ant-card-head .ant-card-head-title {
            padding: 1em .5em .5em 1em !important;
        }
    }

    .body-card-recommendation {
        padding: 3% 10% 4% 5% !important;
    }

    .recommendation-title-card {
        font-style: normal;
        font-weight: bold;
        font-size: 1.4em;
        line-height: 1.2em;
        /* or 150% */
        letter-spacing: 0.01em;
        /* Black */
        color: #1D1D1F;
    }
}


//btn review
.btn-review-round {
    border-radius: 8px !important;
}

.btn-review-approved {
    background: #00C865 !important;
    color: #FFFFFF !important;
    border-color: #00C865 !important;
}

.indicator-content-title {
    font-size: 18px;
    color: #173C7B;
}

.resource-title-single {
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
    /* Black */

    color: #1D1D1F;
}

.resource-description {
    margin-top: 5%;
    margin-left: 1.2%;
    padding-right: 6.5%;

    word-break: break-word;
}

.description-letter-activity {
    font-style: normal;
    font-weight: normal;
    color: #A0A4A8;
    font-size: 0.9em;

    padding: 5% 0 1% 10%;
}

//teacher author resource
.format-text-info {
    color: #1D1D1F;
    font-style: normal;
    font-size: 1.4em;
    letter-spacing: 0.2px;
}

.teacher-author {
    font-weight: 500;
    line-height: 1em;
}

.created-at-resource {
    font-size: 1em;
    font-weight: normal;
    line-height: 0.8em;
}

//text resource
.text-content-view-resource {
    padding-top: 1.5%;
    font-style: normal;
    font-weight: 300;
    font-size: 1.5em; //16px
    line-height: 1.3em;
    letter-spacing: 0.01em;
    color: #52575C;
}

.text-content-title {
    padding-top: 1.2%;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 0.5em;
    letter-spacing: 0.01em;
    color: #000000;
}

//Media player
.media-player-content {
    width: 100%;
    height: 100%; // 372px
    text-align: center;
}

//url
.url-content {
    margin-top: 3%;
}

.url-resource {
    font-style: normal;
    font-weight: 550;
    font-size: 1em;
    line-height: 1em;
    letter-spacing: 0.01em;
    color: #1D1D1F;
}

.url-hyperlink {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    line-height: 1em;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    color: #0066CC;
}

//buttons finally
.btn-resource-text {
    font-style: normal;
    font-weight: bold !important;
    font-size: 0.6em !important;
    display: flex;
    text-align: center;
    letter-spacing: 0.2px;

}

.btn-size {
    height: 120% !important; //5vh
}

.btn-resource-radius {
    border-radius: 0.6em !important;
}

.btn-resource-action:hover {
    color: #0066CC !important;
}

.btn-resource-action {
    color: #A0A4A8 !important;
}

.confirm-delete-activity-guide-modal {
    .ant-modal-content {
        .ant-modal-body {
            padding: 0 1em 1em 1em;

            .btn-activity-guide-delete {
                border-color: #FF5C5C;
                background: #FF5C5C;
                color: #FFF;
                margin-left: 1em;
            }

            .btn-confirm {
                width: 45%;
            }
        }

        .ant-modal-confirm-btns {
            display: none;
        }
    }
}

.btn-is-favorite {
    color: #0066CC !important;
}

.btn-resource-share {
    color: #FFFFFF !important;
    background: #0066CC !important;
}

.btn-resource-copy-guide {
    color: #0066CC !important;
    background: #FFF !important;
    border: 1px solid #0066CC !important;
}

.loading_modal {
    text-align: center !important;
    background: transparent !important;

    .ant-modal-content {
        background: transparent !important;
        border: 0 !important;
        box-shadow: 0 0 !important;
    }
}

.col-btn-action {
    display: flex;
    text-align: center;
}

.ant-rate-star-full {
    filter: invert(75%) sepia(17%) saturate(1902%) hue-rotate(106deg) brightness(180%) contrast(65%);
}

.list-title-resource-file {
    width: 98%;
    color: #FFFFFF;
    background: #5AC8FA;
    border-radius: 46px;
    padding-top: 0.2%;
    padding-left: 1%;

    .img-type-file {
        float: left;
        padding-right: 1%;
    }

    .text-name-file {
        font-style: normal;
        font-weight: initial;
        font-size: 1.2em;
        line-height: 1.6em;
        /* identical to box height, or 131% */

        letter-spacing: 0.03em;
    }

}

.space-type {
    margin-top: -5% !important;
    margin-bottom: 50px;

    .ant-upload-list {
        margin-top: 1% !important;
    }
}

.btn-action-resource-new {
    margin-top: 5%;
}

.list-item-file {
    color: #FFFFFF;
    background: #5AC8FA;
    border-radius: 46px;
    padding-top: 0.2%;
    padding-left: 1%;
    margin-bottom: 1%;
}

.rename-file {
    margin-bottom: 1% !important;
}

.active-edit-file {
    border: 1px solid #CCC !important;
    border-radius: 16px;
    padding: 2% 1.5% 2% 1.5%;
}

.initial-col {
    padding-top: 2%;
}

.indicators-selected-tags {
    padding-bottom: 3%;
}

.spinning-actions-resource {
    text-align: center;
}

.skeleton-actions-resource {
    padding-top: 5%;
    padding-left: 8%;
}

.record-download {
    background: #5AC8FA !important;
    border-radius: 46px !important;
    border-color: #5AC8FA !important;
}

.tag-card {
    overflow: auto;
}

.resource_file {
    //position: relative;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 0 0 8px 0;
    box-shadow: inset 2px 0 3px rgba(0, 0, 0, 0.25);

    .textabc {
        position: absolute;
        left: 10px;
        top: 25px;
        z-index: 1;
    }

    .testqwety {
        position: absolute;
        left: 30px;
        top: 80px;
        z-index: 3;
    }


    .img-card-resource {
        position: relative;
    }

    .type_resource {
        position: absolute;
        bottom: 5px;
        right: 8px;
        font-size: 18px;
    }
}

.resources-wrap {
    .ant-card {
        border-radius: 16px !important;
        box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
    }
}

.container-card-body {
    display: flex;
    align-items: stretch;
    min-height: 100%;
}

.test {
    padding: 2%;
}

ul#menu li {
    display: inline;
}

.separator {
    color: gray;
}

.btn-closed-session {
    float: right;
}

.title-favorite {
    padding-left: 1.5%;
    /* H2 */
    font-style: normal;
    font-weight: bold;
    font-size: 3em;
    line-height: 44px;
    /* identical to box height, or 102% */

    display: flex;
    align-items: center;

    /* ESE dark blue */

    color: #173C7B;
}

.modal-delete-resource {
    color: #FF5C5C;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
}

.modal-approve-resource {
    color: #00C865;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
}

.modal-text-center {
    text-align: center;
}

.modal-title-denied {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 0.2px;
    color: #0066CC;

    margin: 0;
}

.back-btn {
    margin-bottom: 3.5%;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 26px !important;
    line-height: 32px !important;
    letter-spacing: 0.2px !important;
    color: #A0A4A8 !important;
}

.resource-pending {
    opacity: 0.6;
}

.spin-create {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 100%;
    min-width: 100%;
    padding-top: 15% !important;
}

.spin-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    min-width: 100%
}

.audio-home {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: auto;
    width: 90%;
    height: 100%;
}

.contents-resource-show {
    color: #52575C !important;
}

.container-actions {
    display: flex;
    align-items: center;
}

.sub-my-resources {
    .ant-tabs-tab-btn {
        font-size: initial !important;
    }
}

.tabs-days-guides {
    border: 1px solid #CACCCF;
    box-sizing: border-box;
    border-radius: 8px 8px 8px 8px;

    .tabs-container {
        margin: 1% 2% 1% 2%;
    }
}

.col-actions-guides {
    padding-top: 2.5%;
}

.row-spinning-didactic-map {
    height: 100%;
    display: flex;
    align-content: center;
}
