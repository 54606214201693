.errors-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-errors {
    max-width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;

    text-align: center;
}

.register-completed {
    padding-top: 6%;
}
