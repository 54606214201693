@import '../../../../node_modules/antd/dist/antd.less';

#activity-guide-form-component {
    background: #FFFFFF;
    border-radius: 30px;
    padding: 2.5em 2em;
    width: 80%;
    margin: 2em auto;

    .ant-alert {
        margin-bottom: 1.5em;
    }

    #submit-button, #save-as-draft-button {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding: .6em 0 !important;
        height: auto !important;
        box-shadow: 0 8px 16px rgba(0, 126, 255, 0.16);
        border-radius: 8px;
    }

    #component-title {
        font-style: normal;
        font-weight: bold;
        font-size: 43px;
        line-height: 44px;
        color: @primary-color;
        margin-bottom: 1.5em;
    }

    .ant-form-item-label > label {
        color: @primary-color !important;
    }

    .day-assignation-container {
        margin: 1em 0;
        //overflow: hidden;

        .day-assignation-title {
            border-radius: 8px 8px 0 0;
            padding: .6em;
            background: @primary-color;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.2px;
        }

        .day-assignation-content {
            border: 1px solid #CACCCF;
            border-radius: 0 0 8px 8px;
            padding: 1em;

            .sun-editor {
                margin-bottom: 1em;
            }
        }
    }
}