@import '~antd/dist/antd.less';

@primary-color: #3D63E7;
@input-hover-border-color: #3D63E7;
@success-color: #00C865;
@error-color: #FF5C5C;

@layout-body-background: #FFFFFF;

.container-spinning {
    height: 100vh !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
