#bottom-form-fields-component {
    .interaction-col {
        margin-left: 1em;

        .ant-form-item {
            margin-bottom: 0 !important;
        }
    }
    .ant-input-number {
        width: 65%;
        border-radius: 14px !important;
        border: #A0A4A8 1px solid !important;
        box-sizing: border-box;

        .ant-input-number-handler-wrap{
            border-top-right-radius: 14px !important;
            border-bottom-right-radius: 14px !important;
            color: #0066CC !important;
        }
    }
}