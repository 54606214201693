.overlay-labels {
    overflow-y: auto;
    max-height: 20vh;
    box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
}

.form-default-labels {
    border: 1px solid #A0A4A8 !important;
    border-radius: 1em !important; //40px
    // FONT BODY - SELECT
    font-size: 1.3em; //16px
}
