/* recurring variables  */
@font-face {
    font-family: Rubik;
}


.indicators {
    border-radius: 14px !important;
    box-shadow: 0 0 black;
    border: 1px solid #CACCCF !important;

    .ant-card-head {
        min-height: auto !important;
        padding: 0;

        .ant-card-head-indicators {
            padding: 0;
            border-top-right-radius: 14px !important;
            border-top-left-radius: 14px !important;
        }

        .ant-card-head-title {
            padding: 0 !important;
            border-top-right-radius: 14px !important;
            border-top-left-radius: 14px !important;
        }
    }

    .ant-card-body {
        padding: 10px 10px 0 10px !important;
    }
}

.methodology-development {
    box-shadow: 0 0 black;
    border-bottom-left-radius: 14px !important;
    border-bottom-right-radius: 14px !important;

    .ant-card-head {
        min-height: auto !important;
        padding: 0;

        .ant-card-head-indicators {
            padding: 0;
        }

        .ant-card-head-title {
            padding: 0 !important;
        }
    }

    .ant-card-body {
        padding: 0 !important;
    }

    .custom-component {
        padding: 4px;
        border-radius: 0 !important;
    }
}

.methodology {
    box-shadow: 0 0 black;
    border-top-left-radius: 14px !important;
    border-top-right-radius: 14px !important;

    .ant-card-head {
        min-height: auto !important;
        padding: 0;

        .ant-card-head-indicators {
            padding: 0;
            border-top-right-radius: 14px !important;
            border-top-left-radius: 14px !important;
        }

        .ant-card-head-title {
            padding: 0 !important;
            border-top-right-radius: 14px !important;
            border-top-left-radius: 14px !important;
        }
    }

    .ant-card-body {
        padding: 0 !important;
        border-radius: 0 !important;
    }

    .custom-component {
        padding: 4px;
        border-radius: 0 !important;
    }
}

.related-resources {
    border-radius: 14px !important;
    border: 0 !important;

    .ant-card-head {
        min-height: auto !important;
        padding: 0;

        .ant-card-head-indicators {
            padding: 0;
            border-top-right-radius: 14px !important;
            border-top-left-radius: 14px !important;
        }

        .ant-card-head-title {
            padding: 0 !important;
            border-top-right-radius: 14px !important;
            border-top-left-radius: 14px !important;
        }
    }

    .related-resources-content {
        .related-resource-main-content {
            padding: 10px 0 0 10px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
        }

        .related-resources-labels {
            padding: 0 0 10px 10px;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.2px;
        }
    }
}

.content-maintenance {
    background: #FFFFFF;
    width: 100%;
    padding: 2% 10% 5% 10%;
}

.title-maintenance {
    font-style: normal;
    font-weight: bold;
    font-size: 43px;
    line-height: 44px;
    color: #0066CC;
}

//SELECT SCHOOLS
.select-schools {
    width: 100%;

    .ant-select-selector {
        border-radius: 25px !important;
        color: #0066CC !important;
    }

    .ant-select-arrow {
        color: #0066CC;
    }
}

//BTN NEW SCHOOL
.new-school {
    background: #0066CC !important;
    border-radius: 0.5em !important;
}

// NEW SCHOOL
.content-new-school {
    width: 100%;
    margin-top: 2%;
    padding: 1.5% 2% 1.5% 2%;
    border: 0.1em solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 1em;
    box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
}

//titles
.sub-title-maintenance {
    margin-top: 1.5%;

    //FONT
    font-style: normal;
    font-weight: bold;
    font-size: 2em;
    line-height: 1em;
    letter-spacing: 0.002em;
    //COLOR
    color: #0066CC;
}

// MODAL
.btn-modal-maintenance {
    .ant-modal-content {
        border-radius: 1em;
    }

    .ant-modal-header {
        padding-bottom: 0;
        border-bottom: none;
        border-radius: 1em;
    }

    .ant-modal-body {
        padding-top: 2.5%;
    }

    .ant-btn-primary {
        width: 100%;
        background: #0066CC;
        border-radius: 8px;
    }

    .ant-modal-footer {
        border-top: none;
    }

    .ant-modal-close {
        margin-right: 3.5%;
        margin-top: 2.5%;
    }

    .ant-modal-close-x {
        width: 40px;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
        border-radius: 5em;
        box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
    }
}

//BTN SCHOOL STORE
.btn-store-school {
    padding-right: 2% !important;
}

.tag-charge {
    .ant-tag-close-icon {
        color: #0066CC;
    }
}

//btn action table
.btn-table-action-active {
    .anticon {
        color: #00CC00;
    }
}

.btn-table-action-delete {
    .anticon {
        color: #FF0000;
    }
}

.btn-table-action-deactivate {
    .anticon {
        color: #0066CC;
    }
}

.btn-table-action-info {
    .anticon {
        color: #13c2c2;
    }
}

.btn-save-school {
    background: #0066CC !important;
    border-radius: 1em !important;
}

.btn-cancel-school {
    border-radius: 1em !important;
}

.school-active-btn {
    color: #00A000 !important;
    border-color: #00A000 !important;
}

.school-deactivate-btn {
    color: #FF4D4F !important;
    border-color: #FF4D4F !important;
}

.table-maintenance {
    .ant-table {
        //container
        background: #FFFFFF;
        border-radius: 16px !important;
        box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
    }

    .ant-table-thead > tr > th {
        background: #E5F0FF !important;
        //letter
        font-style: normal;
        font-weight: 500;
        font-size: 1.2em;
        line-height: 1.3em;
        letter-spacing: 0.001em;
        color: #0066CC;
    }

    .ant-table-thead > tr > th:last-child {
        border-top-right-radius: 16px !important;
    }

    .ant-table-thead > tr > th:first-child {
        border-top-left-radius: 16px !important;
    }

    .ant-table-tbody > tr:last-child {
        border-top-right-radius: 16px !important;
    }

    .ant-table-tbody > tr:last-child > td:last-child {
        border-bottom-right-radius: 16px !important;
    }

    .ant-table-tbody > tr:last-child > td:first-child {
        border-bottom-left-radius: 16px !important;
    }

    .ant-btn {
        border-radius: 100px !important;
    }
}

.align-flex-end {
    display: flex;
    justify-content: flex-end;
}

.col-btn-deactivate {
    padding-left: 2%;
}

.status_school_actions {
    display: flex;
    justify-content: space-between;
}

.table-unit-planning {
    padding-top: 5%;

    .ant-table {
        border-radius: 16px !important;

        .ant-table-title {
            color: #FFFFFF;
            background: #0066CC;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        .ant-table-tbody {
            background: #FFFFFF;
            box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25);
        }

        .ant-table-tbody > tr:last-child {
            border-top-right-radius: 16px !important;
        }

        .ant-table-tbody > tr:last-child > td:last-child {
            border-bottom-right-radius: 16px !important;
        }

        .ant-table-tbody > tr:last-child > td:first-child {
            border-bottom-left-radius: 16px !important;
        }
    }
}


.modal-units-planning {

    .close-button-props {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        color: rgba(0, 0, 0, 0.45) !important;
        font-size: 1rem !important;
        text-align: center;
        width: 3.5rem !important;
        height: 3.5rem !important;
        line-height: 3.5rem !important;
        box-shadow: 0 5px 10px rgba(23, 60, 123, 0.25) !important;
    }

    .close-button-props:hover {
        color: #000 !important;
    }

    .title-modal-unit {
        font-family: Rubik, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        /* or 123% */

        letter-spacing: 0.2px;

        /* Primary actions, buttons and links */

        color: #0066CC;
    }

}

.tabs-unit-planning {
    width: 100% !important;


    .ant-tabs-tab-active {
        background: #0066CC !important;
        border-radius: 14px !important;

        .ant-tabs-tab-btn {
            color: #FFFFFF !important;
        }
    }

}
