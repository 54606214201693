@site-heading-color: #0D1A26;
@site-text-color: #314659;
@site-text-color-secondary: #697B8C;
@site-border-color-split: #EBEDF0;

.ant-modal-content {
    border-radius: 14px !important;
}

.breadcrumb-resources {
    margin-bottom: 2% !important;
}
