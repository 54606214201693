@import '~antd/dist/antd.less';

.mdl-register-user {
    .mdl-register-user-title {
        text-align: center;
        color: @primary-color
    }

    .success-text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        width: 70%;
        margin: auto auto 2rem;
    }
}
.skeleton-notification{
    width:100%;
    .ant-skeleton-header{
        width:25%;
    }
    .ant-skeleton-content{
        width: 100%;
        padding-right: 5%;
    }
}

.content-notification{
    width: 100% !important;
}